/**
 * PageSubpage
 */

import React from 'react';

import StreamField from 'Components/StreamField';
import { BaseWrap } from 'Containers/Layouts';
import Wysiwyg from 'Components/Wysiwyg';
import Breadcrumbs from 'Components/Breadcrumbs';
import Hero from 'Components/Hero';
import s from './PageStories.module.scss';

class PageStories extends React.PureComponent {
    render() {
        const { breadcrumbs, hero, items, wysiwyg, id, } = this.props;

        return (
            <article className={s['PageStories']}>
                <Breadcrumbs {...breadcrumbs} />
                <Hero {...hero} />

                {wysiwyg && (
                    <div className={s['PageStories__Wysiwyg']}>
                        <Wysiwyg wysiwyg={wysiwyg} />
                    </div>
                )}

                {items && <StreamField items={items} currentPage={id} />}
            </article>
        );
    }
}

PageStories.defaultProps = {
    items: [],
};

export default BaseWrap(PageStories);
